<script setup lang="ts">
import { ref, onMounted } from 'vue'

const theme = ref(localStorage.getItem('theme') || 'light')

// const toggleTheme = () => {
//   theme.value = theme.value === 'light' ? 'dark' : 'light'
//   document.documentElement.setAttribute('data-theme', theme.value)
//   localStorage.setItem('theme', theme.value)
// }

onMounted(() => {
  document.documentElement.setAttribute('data-theme', theme.value)
})
</script>

<template>
  <div>
    <!-- <button @click="toggleTheme">Switch to {{ theme === 'light' ? 'Dark' : 'Light' }} Modes</button> -->
    <RouterView />
  </div>
</template>
